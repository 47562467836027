import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import DispatchActions from '../lib/DispatchActions';
import Strings from '../lib/Strings';

/**
 * Main store for chart related state.
 */
class ChartStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            cards: [],
            cardTemplates: [],
            cardTypes: [],
            sidebar: null,
            chart: null
        };
    }

    reduce(state, action) {
        switch (action.type) {
        case DispatchActions.LOAD_CARDS: {
            // LOAD CARDS INTO STATE
            return {
                ...state,
                cards: action.cards,
                cardTemplates: action.cardTemplates,
                chart: action.chart,
                cardTypes: action.cardTypes
            };
        }
        case DispatchActions.LOAD_CARD_TEMPLATES: {
            // LOAD CARD TEMPLATES INTO STATE
            return {
                ...state,
                cardTemplates: action.cardTemplates,
            };
        }
        case DispatchActions.SAVE_CARD: {
            // SAVE CARD ON SERVER
            return {
                ...state,
                cards: state.cards.map((card) => {
                    if (action.card.id === card.id) {
                        return action.card;
                    } else {
                        return card;
                    }
                }),
                sidebar: action.card
            };
        }
        case DispatchActions.CARD_EDIT: {
            // EDIT CARD -- toggle as the active card in sidebar
            return {
                ...state,
                sidebar: action.card
            };
        }
        case DispatchActions.UPDATE_CARD: {
            return {
                ...state,
                cards: state.cards.map(card => {
                    if (card.id !== action.newCardState.id) {
                        return card
                    } else {
                        return {
                            ...card,
                            ...action.newCardState
                        }
                    } })
            };
        }
        case DispatchActions.DELETE_CARD: {
            // DELETE CARD
            return {
                ...state,
                sidebar: null,
                cards: state.cards.filter((card) => {
                    return card.id !== action.card.id;
                })
            };
        }
        case DispatchActions.NEW_CARD: {
            // NEW CARD BTN CLICK -- NOT SAVE
            return {
                ...state,
                sidebar: {
                    chart_id: state.chart.id,
                    card_type: state.cardTypes[0],
                    content: '',
                    display_content: '',
                    persona_id: action.card.persona_id,
                    type_color: action.card.type_color,
                    box_line_type: Strings.BOX,
                    template: false,
                    template_name: '',
                    height: 60
                }
            };
        }
        case DispatchActions.CREATED_CARD: {
            // NEW CARD CREATED -- ADD TO GRID
            return {
                ...state,
                cards: state.cards.concat({
                    ...action.card,
                    new: true
                }),
                sidebar: action.card
            };
        }
        default: {
            // DEFAULT -- return state as is
            return {
                ...state
            };
        }
        }
    }
}

export default new ChartStore();
