import React from 'react';
import Item from './Item';

export default class Box extends React.Component {
    /**
     * Return proper background style based on card type
     */
    generateBackground() {
        switch (this.props.card.card_type) {
        case 'filled':
            return this.props.card.type_color;
        default:
            return '';
        }
    }

    /**
     * Return correct top border color based on card's type_color
     */

    generateTopBorder() {
        switch (this.props.card.card_type) {
            case 'bordered':
                return `10px solid ${this.props.card.type_color}`;
            default:
                return '';
        }
    }

    /**
     * Return style that will get passed into the item
     */
    generateStyle() {
        if (this.props.card.card_type === 'bordered_outline') {
            return {
                border: `4px solid ${this.props.card.type_color}`
            }
        }

        return {
            background: this.generateBackground(),
            borderTop: this.generateTopBorder()
        };
    }

    render() {
        return (
            <Item
                type='box'
                card={this.props.card}
                className={this.props.card.card_type}
                style={this.generateStyle()}
                active={this.props.active} />
        );
    }
}
