/**
 * String constants
 */
export default class Strings {
    static BOX = 'box';

    static V_LINE = 'vertical_line';

    static H_LINE = 'horizontal_line';

    static UP_ARROW = 'up_arrow';

    static RIGHT_ARROW = 'right_arrow';

    static DOWN_ARROW = 'down_arrow';

    static LEFT_ARROW = 'left_arrow';
}
