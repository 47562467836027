import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import DispatchActions from '../lib/DispatchActions';
import { Editor } from '@tinymce/tinymce-react';

export default class Wysiwyg extends React.Component {
    /**
     * Handle what happens when the text in the WYSIWYG changes...
     * This will get triggered either when pressing the edit button
     * or when the user edits the content. When that happens we will
     * have an up to date state in case the user clicks save.
     */
    handleChange = (e) => {
        /**
         * If the prop card content and the updated editor content is the same
         * just return since nothing has been updated.
         * When a card is updated or something happens to it will trigger this
         * method to be called which we don't want. The only valid "change" to
         * us is a literal content change via user input.
         */
        if (this.props.card.content === e) {
            return;
        }

        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: {
                ...this.props.card,
                content: e
            }
        });
    }

    render() {
        return (
            <Editor
                className={`wysiwyg-${this.props.uuid}`}
                apiKey='e4ouvs4m7beohzyb2xiy6s4honlg9abw8yffj75b2ap48q3m'
                value={this.props.card.content}
                onEditorChange={this.handleChange}
                init={initTinyMce({
                    uuid: this.props.uuid,
                    uploadPath: this.props.uploadPath,
                    selector: 'textarea',
                    sidebar: true
                })} />
        );
    }
}
