import React from 'react';
import { Rnd } from 'react-rnd';
import Client from '../../lib/Client';
import Strings from '../../lib/Strings';
import Dispatcher from '../../dispatchers/Dispatcher';
import DispatchActions from '../../lib/DispatchActions';

export default class Item extends React.Component {
    constructor(props) {
        super(props);

        this.handleItemChange = this.handleItemChange.bind(this);
    }


    isBox() {
        return this.props.type === Strings.BOX;
    }

    isArrow() {
        return this.props.type.match(/up|right|down|left/);
    }

    isArrowLine() {
        return this.props.card.is_arrow_line;
    }

    /**
     * Handle what happens when a user clicks the edit button on a card.
     */
    handleEditClick = () => {
        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: this.props.card
        });
    };

    /**
     * Show edit button when a box is rendered
     */
    renderEditButton() {
        if (this.isBox()) {
            return (
                <button onClick={this.handleEditClick} className='boxEdit btn btn-sm btn-outline-primary'>
                    <i className="fa fa-cog"></i>
                </button>
            );
        } else {
            return '';
        }
    }

    /**
     * Show template star if this card is a template
     */
     renderTemplateStar() {
        if (this.props.card.template) {
            return (
                <div className='boxTemplate'>
                    <i className="fa fa-star"></i>
                </div>
            );
        } else {
            return '';
        }
    }

    /**
     * Render card content
     */
    renderContent() {
        if (this.isBox()) {
            var htmlObject = document.createElement('div');
            htmlObject.innerHTML = this.props.card.display_content;

            $(htmlObject).find('.formula').each(function(index, node) {
                var attributes = {
                    formula:      $(node).data('formula'),
                    default:      $(node).data('default'),
                    max:          Number.parseFloat($(node).data('max')),
                    min:          Number.parseFloat($(node).data('min')),
                    unit:         $(node).data('unit'),
                    delivery:     $(node).data('delivery'),
                    dose:         $(node).data('dose'),
                    dose_range:   $(node).data('dose-range'),
                    is_range:     $(node).data('is-range'),
                    precision:    Number.parseInt($(node).data('precision')),
                    color:        $(node).data('color'),
                    calculated:   $(node).data('calculated'),
                    page:           $(node).data('page'),
                    note:           $(node).data('note'),
                    drug_card_note: $(node).data('drug-card-note'),
                    is_drug_card:   $(node).data('is-drug-card'),
                    is_rx_modal:    $(node).data('is-rx-modal'),
                    hide_meds:      $(node).data('hide-meds'),
                    measure:        $(node).data('measure'),
                    drug_dose_condition: $(node).data('drug-dose-condition'),
                    maximums_only: $(node).data('maximums-only'),
                    rx_note: $(node).data('rx-note'),
                    is_legacy_protocol: $(node).data('is-legacy-protocol'),
                }
                var result = attributes.min;

                var txt = ""
                if (typeof attributes.drug_dose_condition !== 'undefined') {
                    txt += "<span style='font-size: 10pt'> " + attributes.drug_dose_condition + "</span>"
                }

                if (parseInt(attributes.page) > 0) {
                    if(attributes.is_range) {
                        // This is a page link and has a dosage range
                        txt += (attributes.calculated) ? "<span style='font-size: 10pt'><a href='"+ parseInt(attributes.page) +".html'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span></a>"+((isNaN(Number.parseFloat(attributes.measure))) ? "" : "("+attributes.measure+" "+attributes.unit+"/mL) ")+" <br>"+attributes.dose+" - "+attributes.dose_range+" "+attributes.unit+"/kg "+attributes.delivery+"</span>" : "<span style='font-size: 10pt'><a href='"+ parseInt(attributes.page) +".html'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span></a>"+" <br>"+attributes.dose+" - "+attributes.dose_range+" "+attributes.unit+" "+attributes.delivery+"</span>";
                    } else {
                        // This is a page link but not a dosage range
                        txt += (attributes.calculated) ? "<span style='font-size: 10pt'><a href='"+ parseInt(attributes.page) +".html'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span></a>"+((isNaN(Number.parseFloat(attributes.measure))) ? "" : "("+attributes.measure+" "+attributes.unit+"/mL) ")+" <br>"+attributes.dose+" "+attributes.unit+"/kg "+attributes.delivery+"</span>" : "<span style='font-size: 10pt'><a href='"+ parseInt(attributes.page) +".html'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span></a>"+" <br>"+attributes.dose+" "+attributes.unit+" "+attributes.delivery+"</span>";
                    }
                } else {
                    if(attributes.is_range) {
                        txt += (attributes.calculated) ? "<span style='font-size: 10pt'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span>"+((isNaN(Number.parseFloat(attributes.measure))) ? "" : "("+attributes.measure+" "+attributes.unit+"/mL) ")+" <br>"+attributes.dose+ " - "+attributes.dose_range+" "+attributes.unit+"/kg "+attributes.delivery+"</span>" : "<span style='font-size: 10pt'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span>"+" <br>"+attributes.dose+ " - "+attributes.dose_range+" "+attributes.unit+" "+attributes.delivery+"</span>"
                    } else {
                        txt += (attributes.calculated) ? "<span style='font-size: 10pt'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span>"+((isNaN(Number.parseFloat(attributes.measure))) ? "" : "("+attributes.measure+" "+attributes.unit+"/mL) ")+" <br>"+attributes.dose+" "+attributes.unit+"/kg "+attributes.delivery+"</span>" : "<span style='font-size: 10pt'><span style='color:"+attributes.color+"; font-weight: bold;  text-decoration: underline; font-size: 10pt;'>"+attributes.default+"</span>"+" <br>"+attributes.dose+" "+attributes.unit+" "+attributes.delivery+"</span>"
                    }
                }
                var note = attributes.note
                if (typeof note !== 'undefined') {
                    txt = txt + "<span style='font-size: 10pt'> " + note + "</span>"
                }

                if (typeof attributes.maximums_only !== 'undefined') {
                    txt += "<span style='font-size: 10pt'> " + attributes.maximums_only + "</span>"
                }

                $(node).find('.formula-result').html(txt);
            });

            return (
                <div className='cardContent' dangerouslySetInnerHTML={{__html: htmlObject.innerHTML}}></div>
            );
        } else if (this.isArrowLine()) {
          // const icon = `fa fa-caret-${this.props.type.match(/up|right|down|left/)[0]}`;
            const icon = `chart-arrow-${this.props.type.match(/up|right|down|left/)[0]}`;

          return (
              <div key={icon} className={"arrowhead-container"}>
                  <div className={icon} />
              </div>
          );
        } else {
            return '';
        }
    }

    /**
     * Send a network request to our API, saving the new version of a card.
     */
    saveCard(newCard) {
        if (newCard.x === this.props.card.x && newCard.y === this.props.card.y && newCard.height === this.props.card.height && newCard.width === this.props.card.width) {
            return;
        }

        Client.saveCard(newCard);
    }

    handleClick = () => {
        if (this.props.card.box_line_type === Strings.BOX) {
            return;
        }

        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: this.props.card
        });
    };

    /**
     * Figure out what the item x should be. For some reason
     * below we have to divide the x values by 2 when
     * the page loads. However, when adding new lines we
     * can just use the normal value if it's a 'new' line.
     */
    itemX() {
        if (this.props.card.new) {
            return this.props.card.x;
        } else {
            return this.props.card.x;
            // return this.props.card.x / 2;
        }
    }

    calculateSnap() {
        return [20,20];
    }


    handleItemChange(newState) {
        this.props.card.cardChangeHandler({ ...this.props.card, ...newState });
    }

    render() {
        const active = this.props.active === this.props.card.id ? 'active' : '';
        return (
            <Rnd
                className={`${this.props.type} ${active} ${this.props.className}`}
                onResizeStop={(e, direction, ref, delta, position) => {
                    this.handleItemChange({
                        width: ref.style.width,
                        height: ref.style.height,
                        x: position.x,
                        y: position.y
                    });
                }}
                onDragStop={(e, d) => {
                    this.handleItemChange({
                        x: this.props.card.nudged ? d.x : d.lastX,
                        y: this.props.card.nudged ? d.y : d.lastY
                    });
                }}
                style={this.props.style}
                enableResizing={this.props.resizing}
                default={{
                    x: this.itemX(),
                    y: this.props.card.y,
                    width: this.props.card.width,
                    height: this.props.card.height
                }}
                bounds='parent'
                onClick={this.handleClick}
                resizeGrid={this.calculateSnap()}
                dragGrid={this.calculateSnap()}
            >

                {this.renderEditButton()}
                {this.renderTemplateStar()}
                {this.renderContent()}
            </Rnd>
        );
    }
}
