import React from 'react';
import Wysiwyg from '../../Wysiwyg';
import CardType from './CardType';
import StaffLegend from './StaffLegend';
import TemplateSetting from './TemplateSetting';
import Client from '../../../lib/Client';
import Strings from '../../../lib/Strings';

/**
 * Main sidebar component for the card grid.
 */
export default class Sidebar extends React.Component {

    /**
     * Handle what happens when a user clicks save button.
     */
    handleSave = () => {
        Client.saveCard(this.props.card);
        this.props.saveAllCards();
    };

    /**
     * Handle what happens when a user clicks the delete card button
     */
    handleDelete = () => {
        this.props.saveAllCards(); // Added at Matt's request
        if (confirm('Are you sure you want to delete this item?')) {
            Client.deleteCard(this.props.card);
        }
    };

    nudgeLine = (direction) => {
        switch (direction) {
            case 'left':
                Client.saveCard({
                    ...this.props.card,
                    x: this.props.card.x - 1
                }, true)
                $('.active').css('left', '-=1px');
                break;
            case 'right':
                Client.saveCard({
                    ...this.props.card,
                    x: this.props.card.x + 1
                }, true)
                $('.active').css('left', '+=1px');
                break;
            case 'up':
                Client.saveCard({
                    ...this.props.card,
                    y: this.props.card.y - 1
                }, true)
                $('.active').css('top', '-=1px');
                break;
            case 'down':
                Client.saveCard({
                    ...this.props.card,
                    y: this.props.card.y + 1
                }, true)
                $('.active').css('top', '+=1px');
                break;
        }
    }

    /**
     * Determine whether or not to render the delete button. Right now when the
     * user is editing a new card there is no delete button.
     */
    renderDeleteButton() {
        if (this.props.card.id && this.props.userCanDeleteCards) {
            return (
                <button
                    onClick={this.handleDelete}
                    className='btn btn-outline-danger btn-wide mt-2 float-end'>
                    {`Delete ${this.props.card.box_line_type === Strings.BOX ? 'Card' : 'Line'}`}
                </button>
            );
        } else {
            return '';
        }
    }

    /**
     * Method for rendering the actual sidebar, which means a card
     * has been selected.
     */
    renderSidebar() {
        if (this.props.card.box_line_type === Strings.BOX) {
            return (
                <div className='chartSidebarContainer'>
                    <CardType
                        card={this.props.card}
                        cardTypes={this.props.cardTypes} />

                    <StaffLegend
                        card={this.props.card}
                        personas={this.props.personas} />

                    <TemplateSetting
                        card={this.props.card}
                    />


                    <Wysiwyg
                        uploadPath={this.props.uploadPath}
                        uuid={this.props.uuid}
                        card={this.props.card} />

                    <button
                        onClick={this.handleSave}
                        className="btn btn-primary btn-wide mt-2">
                        { this.props.card.id ? 'Update Card' : 'Create Card' }
                    </button>

                    {this.renderDeleteButton()}

                </div>
            );
        } else {
            // return this.renderDeleteButton();
            if (this.props.card.id) {
                return (
                    <div className='text-center'>
                        <button
                            onClick={() => this.nudgeLine('left')}
                            className='btn btn-outline-danger mt-2'>
                            {`Nudge Left`}
                        </button>
                        <button
                            onClick={() => this.nudgeLine('right')}
                            className='btn btn-outline-danger mt-2'>
                            {`Nudge Right`}
                        </button>
                        <button
                            onClick={() => this.nudgeLine('up')}
                            className='btn btn-outline-danger mt-2'>
                            {`Nudge Up`}
                        </button>
                        <button
                            onClick={() => this.nudgeLine('down')}
                            className='btn btn-outline-danger mt-2'>
                            {`Nudge Down`}
                        </button>
                        <br />
                        <button
                            onClick={this.handleDelete}
                            className='btn btn-outline-danger btn-wide mt-2'>
                            {`Delete ${this.props.card.box_line_type === Strings.BOX ? 'Card' : 'Line'}`}
                        </button>
                    </div>
                );
            }
        }
    }

    renderEmptySidebar() {
        return (
            <div className='alert alert-warning'>
                <h3>No card has been selected.</h3>
                <p>To get started, click 'Edit' on a card or 'New Card'.</p>
            </div>
        )
    }

    render() {
        if (this.props.card) {
            return this.renderSidebar();
        } else {
            return this.renderEmptySidebar();
        }
    }
}
