import React from 'react';
import capitalize from 'capitalize';
import Dispatcher from '../../../dispatchers/Dispatcher';
import DispatchActions from '../../../lib/DispatchActions';

export default class CardType extends React.Component {
    renderCardTypeOptions() {
        return this.props.cardTypes.map((option) => {
            return (
                <option
                    key={option}
                    value={option}>
                    {option.split('_').map(p => capitalize(p)).join(' ')}
                </option>
            );
        });
    }

    handleSelectChange(e, name) {
        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: {
                ...this.props.card,
                [name]: e.target.value
            }
        });
    }

    render() {
        return (
            <div>
                <label>Card Type</label>
                <select
                    onChange={(e) => this.handleSelectChange(e, 'card_type')}
                    value={this.props.card.card_type}
                    className='form-control custom-select mb-2'>
                    {this.renderCardTypeOptions()}
                </select>
            </div>
        );
    }
}
