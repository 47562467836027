import axios from 'axios';
import Dispatcher from '../dispatchers/Dispatcher';
import DispatchActions from './DispatchActions';
import Strings from './Strings';

/**
 * Client actions for making AJAX responses to our server and
 * dispatching data back to the relevant store(s).
 */
export default class Client {
    /**
     * Send a GET request to get card templates
     */
     static cardTemplates(chartId) {
        return axios.get(`/api/cards/card_templates?chart_id=${chartId}`, {
        }).then((response) => {
            Dispatcher.dispatch({
                type: DispatchActions.LOAD_CARD_TEMPLATES,
                cardTemplates: response.data
            });
        });
    }

    /**
     * Send a request up to the server to either post or patch a card
     * for creating or updating a card.
     */
    static saveCard(card, nudged, lastCard = true, toggleSaveButtonFunction = undefined) {
        /**
         * For right now we're determining whether or not to save/edit based
         * on if an id is assigned to the card. If so use edit, otherwise create.
         */
        if (card.id) {
            return this.editCard(card, nudged, lastCard, toggleSaveButtonFunction);
        } else {
            return this.createCard(card);
        }
    }

    /**
     * Delete a specific card from its chart.
     */
    static deleteCard(card) {
        $saveChartModal.modal('show');

        return axios.delete(`/api/cards/${card.id}`, {
            card: { ...card }
        }).then(() => {
            saveCardModalShowing ? closeSaveChartModal() : pendingCardRequestsFinished = true

            Dispatcher.dispatch({
                type: DispatchActions.DELETE_CARD,
                card: card
            });
        });
    }

    /**
     * Send a PATCH request up to the server to update a card.
     */
    static editCard(card, nudged = false, lastCard, toggleSaveButtonFunction) {
        $saveChartModal.modal('show');

        return axios.patch(`/api/cards/${card.id}`, {
            card: { ...card }
        }).then((response) => {
            if (lastCard) {
                if (toggleSaveButtonFunction !== undefined) {
                    toggleSaveButtonFunction(false);
                }

                saveCardModalShowing ? closeSaveChartModal() : pendingCardRequestsFinished = true
            }

            Dispatcher.dispatch({
                type: DispatchActions.SAVE_CARD,
                card: { ...response.data, nudged: nudged }
            });
        });
    }

    /**
     * Send a POST request to create a new card.
     */
    static createCard(card, isNewLine, template=false) {
        if (!template) {
            $saveChartModal.modal('show');
        }

        return axios.post(`/api/cards`, {
            card: { ...card },
            device_chart_width: isNewLine ? parseInt($('.chartContainer').css('width')) : null
        }).then((response) => {
            saveCardModalShowing ? closeSaveChartModal() : pendingCardRequestsFinished = true

            Dispatcher.dispatch({
                type: DispatchActions.CREATED_CARD,
                card: response.data
            });
        });
    }

    static createLine(type, chartId) {
        const card = {
            chart_id: chartId,
            box_line_type: type,
            card_type: 'outline',
        };

        this.createCard(card, true);
    }
}