export default class DispatchActions {
    /**
     * Signal to save a card
     */
    static SAVE_CARD = "SAVE_CARD";

    /**
     * Load the cards into state
     */
    static LOAD_CARDS = "LOAD_CARDS";

    /**
     * Load the card templates into state
     */
     static LOAD_CARD_TEMPLATES = "LOAD_CARD_TEMPLATES";

    /**
     * Edit a specific card
     */
    static CARD_EDIT = "CARD_EDIT";

    /**
     * Delete a specific card
     */
    static DELETE_CARD = "DELETE_CARD";

    /**
     * Create a new card
     */
    static NEW_CARD = "NEW_CARD";

    /**
     * A new card has been created and saved on the server.
     */
    static CREATED_CARD = "CREATED_CARD";

    /**
     * A card has been moved or resized.
     */
    static UPDATE_CARD = "UPDATE_CARD"
}
