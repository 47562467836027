import React from 'react';
import capitalize from 'capitalize';
import Dispatcher from '../../dispatchers/Dispatcher';
import DispatchActions from '../../lib/DispatchActions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Client from '../../lib/Client';

export default class CardTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: false, templateId: null};
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleShow = () => {
    Client.cardTemplates(this.props.chartId)
    this.setState({show: true});
  }

  addCard = (card) => {
    Client.createCard({...card, id: null, chart_id: this.props.chartId, created_at: null, updated_at: null, x: 0, y: 0, template: false, template_name: ''}, false, true);
    this.handleClose();
  }

  render() {
    return (
      <>
        <button
            onClick={this.handleShow}
            title="Add a new card from template"
            className="btn btn-sm btn-success">
            <i className="fa-solid fa-copy"></i>
        </button>
  
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          // backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Card Templates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accordion defaultActiveKey="0">
              {this.props.cardTemplates.map((card, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <button
                      onClick={() => this.addCard(card)}
                      title="Add a new card from template"
                      className="btn btn-sm btn-success"
                      style={{marginRight: '1em'}}>
                      +
                    </button>
                    {card.template_name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='cardContent' dangerouslySetInnerHTML={{__html: card.display_content}}></div>
                    
                    <div style={{textAlign: 'right'}}>
                      <button
                        onClick={() => this.addCard(card)}
                        title="Add a new card from template"
                        className="btn btn-sm btn-success">
                        Add card template
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}