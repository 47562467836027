$(function () {
    var $modal = $('#duplicate-protocol-modal');

    $modal.on('show.bs.modal', function (event) {
        var $modal = $(this),
            $button = $(event.relatedTarget),
            title = $button.data('modal-title'),
            protocolNumber = $('.dpid').data('protocol-number'),
            protocolId = $button.data('id'),
            submitPath = $button.data('submit-path');

        $modal.find('.modal-title').text(title);
        $modal.find('.modal-body input#protocol-id').val(protocolId);
        $modal.find('.modal-body input#submit-url').val(submitPath);
        // $modal.find('.modal-body input#protocol-number').val(protocolNumber);
    });

    $modal.on('shown.bs.modal', function () {
        setTimeout(function () {
            $('select#customer-select + span.select2.select2-container').attr('style', 'width: 100% !important;');
        }, 50);
    });

    $('#duplicate-modal-submit', $modal).click(function () {
        var $protocolId = $('#protocol-id').val(),
            $customerId = $('#customer-select').val(),
            $protocolNumber = $('#protocol-number').val(),
            $submitPath = $('#submit-url').val();

        $('.duplicate-modal-cancel').hide();
        $('#duplicate-modal-submit').hide();
        $('#spinner-text').removeClass('d-none');

        if ($customerId < 1) {
            $('#customer-error').removeClass('d-none');
        }

        if ($protocolNumber < 1) {
            $('#number-error').removeClass('d-none');
        }

        if ($customerId > 0 && $protocolNumber > 0) {
            $('#customer-error, #number-error').addClass('d-none');

            $.ajax({
                url: $submitPath,
                dataType: 'json',
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
                },
                data: { new_customer_id: $customerId, id: $protocolId, protocol_number: $protocolNumber },
                success: function (data) {
                    if (data > 0) {
                        window.location.href = '/customers/' + $customerId + '/protocols';
                    } else {
                      if (data.duplicateSuccess) {
                        reloadPage();
                      } else {
                        showResponseModal(data);
                      }
                    }
                },
                error: function (response) {
                  // console.log(response);

                  // if (response.responseText) {
                  //   alert(response.responseText)
                  // }

                  reloadPage();
                }
            });
        }
    });
});

function showResponseModal(modalResponse) {
  let $responseModal = $('#duplicate-protocol-response-modal');

  $responseModal.find('.modal-title').text(modalResponse.title)
  $responseModal.find('.modal-body').html(modalResponse.body);
  $responseModal.find('.modal-dialog').css('max-width', '80%').css('text-align', 'none');
  $responseModal.modal('show');
  $responseModal.on('hidden.bs.modal', () => { reloadPage() });
}

function reloadPage() {
  window.location.href = window.location.href
}
