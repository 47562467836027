import React from 'react';
import Item from './Item';

export default class Line extends React.Component {
    isHorizontal() {
        return this.props.type.match(/horizontal|left|right/) !== null;
    }

    generateResizing() {
        const hor = this.isHorizontal();

        return {
            bottom: !hor,
            top: !hor,
            right: hor,
            left: hor
        };
    }

    lineTypes() {
        const type = this.props.type;

        if (this.props.card.is_arrow_line) {
            switch (type) {
                case 'up':
                case 'down':
                    return `${type} vertical`;
                case 'left':
                case 'right':
                    return `${type} horizontal`;
                default:
                    return type;
            }
        } else {
            return type;
        }
    }

    render() {
        return (
            <Item
                type={`line ${this.lineTypes()}`}
                card={this.props.card}
                resizing={this.generateResizing()}
                active={this.props.active} />
        );
    }
}
