function setTelephoneInputMasks(element_selector = '[type=tel]', mask = '999-999-9999') {
  setInputMasks(element_selector, mask);
}

function setDateInputMasks(element_selector = '[data-mask="date"]', mask = '99/99/9999') {
  setInputMasks(element_selector, mask);
}

function setIntegerInputMasks(element_selector = '[data-mask="integer"]', mask = '9[9][9]') {
  setInputMasks(element_selector, mask, false);
}

function setPercentageInputMasks(element_selector = '[data-mask="percentage"]') {
  $(element_selector).inputmask("numeric", {
    min: 0,
    max: 100
  });
}

function setZipCodeInputMasks(element_selector = '[data-mask="Zip/Postal"]', mask = '99999') {
  setInputMasks(element_selector, mask);
}

window.setMedicationInputMasks = function(decimal_places = 2, element_selector = '[data-mask="medication"]', mask = '99999') {
  $(element_selector).inputmask({
    alias:          'decimal',
    groupSeparator: '',
    autoGroup:      true,
    // digits:         2, //removed 7/27 per request of responsoft
    // digitsOptional: false,
    allowMinus:     false,
    placeholder:    "0.0",
    greedy:         false
  });
}

function setCurrencyInputMasks(element_selector = '[data-mask="currency-amount"]') {
  $(element_selector).inputmask("currency", {
    groupSeparator: '',
    placeholder:    '0.00',
    numericInput:   true,
    autoGroup:      true,
    allowMinus:     false,
  });
}

function setInputMasks(element_selector, mask, greedy = true) {
  $(element_selector).inputmask({ mask: mask, greedy: greedy });
}