import React from 'react';
import Dispatcher from '../../../dispatchers/Dispatcher';
import DispatchActions from '../../../lib/DispatchActions';

export default class StaffLegend extends React.Component {
    handlePersonaChange = (e) => {
        const selection = e.target.value;
        const type_color = this.retrievePersonaColor(selection);
        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: {
                ...this.props.card,
                persona_id: selection,
                type_color: type_color
            }
        });
    };

    retrievePersonaColor = (persona_id) => {
        return this.props.personas.filter((persona) => {
            return persona.identifier === persona_id
        })[0].color;
    }

    renderPersonaOptions() {
        return this.props.personas.map((persona) => {
            return (
                <option
                    key={persona.identifier}
                    value={persona.identifier}>
                    {persona.title}
                </option>
            );
        })
    }

    colorBlockStyle() {
        if (this.props.card.type_color) {
            return {
                height: '20px',
                background: this.props.card.type_color
            };
        } else {
            return {
                display: 'none'
            };
        }
    }

    render() {
        return (
            <div>
                <label>Staff Legend</label>
                <select
                    onChange={this.handlePersonaChange}
                    value={this.props.card.persona_id}
                    className='form-control custom-select mb-2'>
                    {this.renderPersonaOptions()}
                </select>

                <div className='color-block mb-2' style={this.colorBlockStyle()}/>
            </div>
        );
    }
}
