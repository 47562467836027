import React from 'react';
import Dispatcher from '../../../dispatchers/Dispatcher';
import DispatchActions from '../../../lib/DispatchActions';
import Strings from '../../../lib/Strings';
import Client from '../../../lib/Client';
import CardTemplateModal from '../CardTemplateModal'

/**
 * Main component for the chart toolbar that contains action buttons
 * like adding lines, new cards, arrows, etc.
 */
export default class ChartToolbar extends React.Component {
    /**
     * Handle 'new card' button click
     */
    handleNewCardClick = () => {
        Dispatcher.dispatch({
            type: DispatchActions.NEW_CARD,
            card: {
                persona_id: this.props.personas[0]['identifier'],
                type_color: this.props.personas[0]['color']
            }
        });
    };

    handleNewLineClick(type) {
        Client.createLine(type, this.props.chart.id);
    }

    render() {
        return (
            <div className="chartToolbar mb-3" style={{ paddingTop: 100}}>
                <button
                    onClick={this.handleNewCardClick}
                    title="Add a new card"
                    className="btn btn-sm btn-success">
                    <i className="fas fa-plus"></i>
                </button>

                <CardTemplateModal cardTemplates={this.props.cardTemplates} chartId={this.props.chart.id}/>

                <button
                    onClick={() => this.handleNewLineClick(Strings.H_LINE)}
                    title="Add a horizontal line"
                    className="btn btn-sm btn-info">
                    <i className="fas fa-arrows-alt-h"></i>
                </button>
                <button
                    onClick={() => this.handleNewLineClick(Strings.V_LINE)}
                    title="Add a vertical line"
                    className="btn btn-sm btn-info">
                    <i className="fas fa-arrows-alt-v"></i>
                </button>
                <button
                    onClick={() => this.handleNewLineClick(Strings.UP_ARROW)}
                    title="Add an up arrow"
                    className="btn btn-sm btn-warning">
                    <i className="fas fa-arrow-up"></i>
                </button>
                <button
                    onClick={() => this.handleNewLineClick(Strings.RIGHT_ARROW)}
                    title="Add a right arrow"
                    className="btn btn-sm btn-warning">
                    <i className="fas fa-arrow-right"></i>
                </button>
                <button
                    onClick={() => this.handleNewLineClick(Strings.DOWN_ARROW)}
                    title="Add a down arrow"
                    className="btn btn-sm btn-warning">
                    <i className="fas fa-arrow-down"></i>
                </button>
                <button
                    onClick={() => this.handleNewLineClick(Strings.LEFT_ARROW)}
                    title="Add a left arrow"
                    className="btn btn-sm btn-warning">
                    <i className="fas fa-arrow-left"></i>
                </button>
            </div>
        );
    }
}
