import React from 'react';
import Dispatcher from '../../../dispatchers/Dispatcher';
import DispatchActions from '../../../lib/DispatchActions';

export default class TemplateSetting extends React.Component {

    handleCheckboxChange(e) {
        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: {
                ...this.props.card,
                template: e.target.checked
            }
        });
    }

    handleNameChange(e) {
        Dispatcher.dispatch({
            type: DispatchActions.CARD_EDIT,
            card: {
                ...this.props.card,
                template_name: e.target.value
            }
        });
    }


    render() {
        return (
            <>
                <hr />
                <div>
                    <label>
                        <input type="checkbox" className="mb-2" style={{marginRight: '5px'}} checked={this.props.card.template} onChange={(e) => this.handleCheckboxChange(e)}/>
                        Save as template
                    </label>

                    <br />

                    {this.props.card.template && 
                        <label>
                            Template name
                            <input className="form-control mb-2 w-100" value={this.props.card.template_name} onChange={(e) => this.handleNameChange(e)}/>
                        </label>
                    }
                </div>
                <hr />
            </>
        );
    }
}
