import React from 'react';
import { Container } from 'flux/utils';
import Dispatcher from '../../dispatchers/Dispatcher';
import DispatchActions from '../../lib/DispatchActions';
import Strings from '../../lib/Strings';
import ChartStore from '../../stores/ChartStore';
import Box from './Box';
import Line from './Line';
import Sidebar from './sidebar/Sidebar';
import ChartToolbar from './toolbar/ChartToolbar';
import CardTemplateModal from './CardTemplateModal';
import Client from '../../lib/Client';
import axios from 'axios';

/**
 * Main container for rendering out charts connected to the chart store.
 */
class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSaveButton = this.toggleSaveButton.bind(this);
    this.handleCardChange = this.handleCardChange.bind(this);
  }


  static getStores() {
    return [ChartStore];
  }

  static calculateState() {
    const state = {
      toggled: false,
      ...ChartStore.getState()
    };

    return state;
  }

  componentWillMount() {
    /**
     * Before the component mounts we need to load up some data into
     * the store. The other way to do this would be to just load up
     * the page id and load cards that way. Since we already have the
     * cards loaded we're accepting them as props and then dispatching
     * when the component will mount, loading the cards into the store.
     * This should allow us to update the individual cards and it
     * will re render properly when the store updates state.
     */
    Dispatcher.dispatch({
      type:      DispatchActions.LOAD_CARDS,
      cards:     this.props.cards,
      cardTemplates: this.props.cardTemplates,
      chart:     this.props.chart,
      cardTypes: this.props.cardTypes
    });
  }

  /**
   * Iterate through our card props, rendering each one out based
   * on its specific type.
   */
  renderCards() {
    const active = this.state.sidebar ? this.state.sidebar.id : null;

    return this.state.cards.map((card) => {
      let props = { key: card.id, card: { ...card, cardChangeHandler: this.handleCardChange }, active: active }

      if (card.box_line_type === Strings.BOX) {
        return (
          <Box {...props}/>
        );
      } else {
        return (
          <Line
            {...props}
            type={card.box_line_type.split('_')[0]}
          />
        );
      }
    });
  }

  toggleSaveButton(toggled) {
    this.setState({ toggled: toggled });
  }

  handleSaveAll() {
    this.toggleSaveButton(true);
    $saveChartModal.modal('show');
    axios.post('/api/cards/save_all', {
      cards: this.state.cards
    }).then((response) => {
      this.toggleSaveButton(false);
      saveCardModalShowing ? closeSaveChartModal() : pendingCardRequestsFinished = true
      return response
    });

    // this.state.cards.forEach((card, index, array) => {
    //   Client.saveCard(card, false,  index === array.length - 1)
    // });
  }

  handleCardChange(newCardState) {
    Dispatcher.dispatch({
      type: DispatchActions.UPDATE_CARD,
      newCardState: newCardState
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="sticky-top">
          <ChartToolbar chart={this.props.chart} personas={this.props.personas} cardTemplates={this.state.cardTemplates} />

          <Sidebar
            uuid={this.props.uuid}
            uploadPath={this.props.uploadPath}
            card={this.state.sidebar}
            cardTypes={this.props.cardTypes}
            personas={this.props.personas}
            userCanDeleteCards={this.props.userCanDeleteCards}
            saveAllCards={() => this.handleSaveAll()}
          />

          {/* <CardTemplateModal cardTemplates={this.props.cardTemplates} /> */}

          </div>
        </div>
        <div className="col-lg-9">
          <div className="text-end">
            <button
              onClick={() => this.handleSaveAll()}
              title="Save Cards"
              className={`btn btn-danger mb-3 ${this.state.toggled ? " toggled" : ""}`}
              disabled={this.state.toggled}>
              {this.state.toggled ? 'Saving...' : 'Save Page'}
            </button>
          </div>
          <div className="chartContainer">
            {this.renderCards()}
          </div>
        </div>
      </div>
    );
  }
}

const ChartContainer = Container.create(Chart);

export default ChartContainer;
