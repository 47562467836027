// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "custom/wysiwyg"
import "custom/input-masks"
import "custom/duplicate-modal"
import "custom/delete-confirmation-prompts"

$(function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $('.chartContainer a').on('click',function(e) {
        e.preventDefault();
    });

    window.showSpinner = function () {
        $("body").css("cursor", "progress");
    }
});




require("@nathanvda/cocoon")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
