$(() => {
  $('a.delete-customer').on('click', (e) => {
    return confirmPromptDelete('customer', e)
  })

  $('a.delete-protocol').on('click', (e) => {
    return confirmPromptDelete('protocol', e)
  })
})

function confirmPromptDelete(promptObject, e, defaultValue = '') {
  if (prompt(`Are you sure you want to delete this ${promptObject}?\n\nEnter 'delete' below to confirm this action`, defaultValue) !== 'delete') {
    $(e.target).parent().click();
    return false;
  }
}